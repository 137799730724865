import React from 'react';

const ScheduleForCalendar = () => {
    return (
        <iframe
            src="https://calendar.google.com/calendar/embed?src=momoyama.tech%40gmail.com&ctz=Asia%2FTokyo"
            style={{ border: 0 }}
            width={"100%"}
            height={600}
            title="schedule"
        />
    )
}

export default ScheduleForCalendar