import React from 'react';
import { Paper, TableContainer, Table, TableBody, TableRow, TableCell } from '@mui/material';

const InfomationTable = () => {
    return (
        <TableContainer component={Paper}>
            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell component="th" scope="row">活動の頻度</TableCell>
                        <TableCell>そこそこ</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell component="th" scope="row">どこで活動しているか</TableCell>
                        <TableCell>カンタベリー館2階 会議室1と4</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell component="th" scope="row">活動時間</TableCell>
                        <TableCell>平日 16:40~</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell component="th" scope="row">見学は可能か</TableCell>
                        <TableCell>可能</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell component="th" scope="row">会費はあるか</TableCell>
                        <TableCell>月額100円(年最大1000円)</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell component="th" scope="row">メンバーの雰囲気</TableCell>
                        <TableCell>最高</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell component="th" scope="row">どのくらいの規模感か</TableCell>
                        <TableCell>大きい</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell component="th" scope="row">入会に規約はあるか</TableCell>
                        <TableCell>ない</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell component="th" scope="row">Twitter</TableCell>
                        <TableCell>
                            <a target="blank" href="https://twitter.com/momoyama_tech">https://twitter.com/momoyama_tech</a>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell component="th" scope="row">Instagram</TableCell>
                        <TableCell>
                            <a target="blank" href="https://www.instagram.com/momoyama.tech/">https://www.instagram.com/momoyama.tech/</a>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell component="th" scope="row">Email</TableCell>
                        <TableCell>
                            <a href="mailto:tech@andrew.ac.jp">tech@andrew.ac.jp</a>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell component="th" scope="row">部長</TableCell>
                        <TableCell>藤倉</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default InfomationTable