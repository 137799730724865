import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';

const ClubDescriptionCard = () => {
    return (
        <Card style={{ height: '100%' }}>
            <CardContent>
                <Typography gutterBottom sx={{ typography: { xs: 'h4', sm: 'h2'  } }}>
                    桃山学院大学テック部へ<br />ようこそ
                </Typography>
                <Typography sx={{ typography: { xs: 'h5', sm: 'h4'  } }} color="text.secondary">
                    桃山学院大学で様々な技術(TECH)を学ぶために活動をしているテック部です!<br />
                    現在部員は50名で、IT, ボランティア, 資格取得など多様に活動しています!<br />
                    学内の方で、わたしたちの活動を見て興味があれば、ぜひ見学などの連絡をください!<br />
                    学外の方で、わたしたちの活動に興味や相談がある方の連絡もお待ちしています!
                </Typography>
            </CardContent>
        </Card>
    )
}

export default ClubDescriptionCard