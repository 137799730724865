import React from 'react';
import styles from './NotionArticleCard.module.css'
import { Card, CardMedia, CardContent, Typography } from '@mui/material';

const NotionArticle = (props) => {
    let imageUrl = "https://storage.userlocal.jp/chatbot/icon/6e960b0d-4d8f-4476-9882-c162bf7332ff.png"
    if (props.article['properties']['thumbnail']['files'].length) {
        if (!(props.article['properties']['thumbnail']['files'][0]['file'] == null)) {
            imageUrl = props.article['properties']['thumbnail']['files'][0]['file']['url']
        }
    }
    return (
        <Card className={styles.error}>
            <a href={props.article['public_url']} target="_blank" rel="noreferrer">
                <CardMedia
                    image={ imageUrl }
                    title="green iguana"
                    style={{aspectRatio: '16 / 9'}}
                />
            </a>
            <CardContent>
                <Typography gutterBottom variant="h5" component="div" style={{textOverflow: "ellipsis"}}>
                    { props.article['properties']['title']['title'][0]['plain_text'] }
                </Typography>
                <a href={props.article['public_url']} target="_blank" rel="noreferrer">
                    詳細を見る
                </a>
                <Typography variant="body2" color="text.secondary" align="right">
                    公開日: { props.article['properties']['public_date']['date']['start'] }
                </Typography>
            </CardContent>
        </Card>
    )
}

export default NotionArticle