import {React, useEffect, useState } from 'react';
import './App.css';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Grid } from '@mui/material';
import { Timeline } from 'react-twitter-widgets';
import axios from 'axios';

import NotionArticle from './components/NotionArticleCard';
import ScheduleForCalendar from './components/ScheduleForCalendar';
import ClubDescriptionCard from './components/ClubDescriptionCard';
import InfomationTable from './components/InfomationTable';
import HeaderForDesktop from './components/HeaderForDesktop';
import HeaderForMobile from './components/HeaderForMobile';

const App = () => {
	const [newtNewsArticlesState, setNewtNewsArticlesState] = useState([]);
	const [newtITArticlesState, setNewtITArticlesState] = useState([]);
	const [newtGameArticlesState, setNewtGameArticlesState] = useState([]);
	const [newtVolunteerArticlesState, setNewtVolunteerArticlesState] = useState([]);

	useEffect(() => {
		axios.get("https://script.google.com/macros/s/AKfycbytywXehALcyAPm-6hQjHYgRjPq-ZzomJueKuwSHbD-_V8Wknd_hLidkucdHY3y4_-7/exec")
		.then(function(response) {
			const notionResults = response.data.results

			setNewtNewsArticlesState(notionResults.filter(
				(notionResult) => notionResult
									.properties
									.tag
									.multi_select
									.some(select => Object.values(select).includes("News"))
			))
			setNewtITArticlesState(notionResults.filter(
				(notionResult) => notionResult
									.properties
									.tag
									.multi_select
									.some(select => Object.values(select).includes("IT"))
			))
			setNewtGameArticlesState(notionResults.filter(
				(notionResult) => notionResult
									.properties
									.tag
									.multi_select
									.some(select => Object.values(select).includes("Game"))
			))
			setNewtVolunteerArticlesState(notionResults.filter(
				(notionResult) => notionResult
									.properties
									.tag
									.multi_select
									.some(select => Object.values(select).includes("Volunteer"))
			))
		})
	}, []);

	return (
		<div className="App">
			<Grid container style={{ maxWidth: '100%', backgroundColor: '#a1051d' }} sx={{ display: { xs: 'none', sm: 'block' } }}>
				<HeaderForDesktop />
			</Grid>
			<Grid container sx={{ display: { xs: 'block', sm: 'none' } }}>
				<HeaderForMobile />
			</Grid>
			<Grid container spacing={0}>
				<Grid item xs={12} md={8}>
					<ClubDescriptionCard />
				</Grid>
				<Grid item md={4} sx={{ display: { xs: 'none', sm: 'block' } }}>
					<Timeline dataSource={{ sourceType: 'profile', screenName: 'momoyama_tech'}} options={{height: '400'}} />
				</Grid>
			</Grid>
			<Grid container spacing={1}>
				<Grid item xs={12} style={{ backgroundColor: 'blue'}}>
					<h2>テックニュース</h2>
				</Grid>
				{newtNewsArticlesState.map((article) => (
				<Grid item xs={12} md={3}>
					<NotionArticle article={article} />
				</Grid>
				))}
			</Grid>
			<Grid container spacing={1}>
				<Grid item xs={12} style={{ backgroundColor: 'blue'}}>
					<h2>活動内容</h2>
				</Grid>
				<Grid item xs={12} style={{ backgroundColor: 'red'}}>
					<h3>IT</h3>
				</Grid>
				{newtITArticlesState.map((article) => (
				<Grid item xs={12} md={3}>
					<NotionArticle article={article} />
				</Grid>
				))}
				<Grid item xs={12} style={{ backgroundColor: 'red'}}>
					<h3>Game</h3>
				</Grid>
				{newtGameArticlesState.map((article) => (
				<Grid item xs={12} md={3}>
					<NotionArticle article={article} />
				</Grid>
				))}
				<Grid item xs={12} style={{ backgroundColor: 'red'}}>
					<h3>Volunteer</h3>
				</Grid>
				{newtVolunteerArticlesState.map((article) => (
				<Grid item xs={12} md={3}>
					<NotionArticle article={article} />
				</Grid>
				))}
			</Grid>
			<Grid container spacing={1}>
				<Grid item xs={12} style={{ backgroundColor: 'blue'}}>
					<h2>スケジュール</h2>
				</Grid>
				<Grid item xs={12}>
					<ScheduleForCalendar />
				</Grid>
			</Grid>
			<Grid container spacing={0}>
				<Grid item xs={12} style={{ backgroundColor: 'blue'}}>
					<h2>活動詳細</h2>
				</Grid>
				<Grid item xs={12}>
					<InfomationTable />
				</Grid>
			</Grid>
		</div>
	)
};


export default App;